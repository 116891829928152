.file-list {
  list-style: none;
  padding: 0;
}

.icon-button {
  cursor: pointer;
  transition: color 0.3s ease;
}

.icon-button:hover {
  brightness: 1.5;
}

.button-main {
  background-color: #3f3f3f !important;
  color: white !important;
}
.button-main:hover {
  background-color: #3f3f3f80 !important;
  color: white !important;
}

.button-secondary {
  background-color: #e91e63 !important;
  color: white !important;
}

.button-secondary:hover {
  background-color: #e91e6380 !important;
  color: white !important;
}

.view-button {
  color: #3f3f3f !important;
}

.delete-button {
  color: #e91e63 !important;
}
