/* Global styling for body */
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  /* Setting up font family for body */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* Enhancing font rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Stylings for code tag */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Overriding color for MuiIcon with fontSizeSmall inside MuiList */
.MuiList-root .MuiIcon-fontSizeSmall {
  color: white;
}

/* Overriding color for MuiTypography with colorTextSecondary inside button */
button .MuiTypography-colorTextSecondary {
  color: white;
}

/* Styling for scroll bar */
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

/* Styling for scroll bar track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

/* Styling for scroll bar thumb */
::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
  border-radius: 20px;
}

/* Overriding focus background color for MuiSelect */
.MuiSelect-select:focus {
  background-color: white !important;
}

/* Global styling for anchor tag */
a {
  text-decoration: none;
  color: inherit;
}

/* Overriding background color for MuiInputBase */
.MuiInputBase-root {
  background-color: #fff;
}

/* Hiding MuiDataGrid panelHeader */
.MuiDataGrid-panelHeader {
  display: none;
}

/* Overriding min height for MuiToolbar */
.MuiToolbar-regular {
  min-height: 48px !important;
}

/* Styling for footer paragraph inside anchor tag */
.ec-footer a p {
  font-size: 0.85rem;
  color: #666;
}

/* Styling for input-files class */
.input-files {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* Styling for item-base class inside input-files class */
.input-files .item-base {
  width: 200px;
  height: 30px;
  padding: 0px;
  margin-right: 1em;
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem !important;
  border-left-width: 1px !important;
  position: relative;
  line-height: initial;
  background-color: #fff;
  border: 1px solid #e91e63;
}

/* Styling for item-file class inside input-files class */
.input-files .item-file {
  border-width: 2px !important;
  border-style: dashed !important;
  border-color: #dadada;
}

/* Styling for item-actions class inside item-file class */
.input-files .item-file .item-actions {
  position: absolute;
  bottom: -32px;
  width: 104%;
}

/* Styling for item-input class inside input-files class */
.input-files .item-input {
  border-width: 2px !important;
  border-style: dashed !important;
  color: #e91e63;
  cursor: pointer;
}

/* Styling for item-input class with over state */
.input-files .item-input.over {
  border-color: #009da0;
}

/* Hiding input inside item-input class */
.input-files .item-input input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.selectedRow {
  background-color: #e91e6330 !important;
}

.editable,
.secondEditable {
  outline: none;
  margin: 0 0 20px 0;
  padding: 0 0 20px 0;
  border-bottom: 0px solid #dbdbdb !important;
}

.ql-toolbar-hidden {
  display: none !important;
}

.layout-action-buttons-wrapper:hover .layout-action-buttons,
.draggable-image:hover .layout-action-buttons {
  visibility: visible !important;
}

.layout-action-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.__markerjs2_ {
  z-index: 9999999999999 !important;
}

.report-leftPanel {
  background-color: #ecfcf4;
  text-align: right;
  border-right: 2px solid #1ebc86;
  padding-right: 8;
}

.report-patientLeftPanel {
  background-color: #feffff;
  text-align: right;
  padding: 8;
}

.report-content {
  padding-top: 2;
  padding-left: 18px;
}
.report-right {
  padding-left: 18;
}
.layout-action-buttons {
  background-color: rgba(255, 255, 255, 1);
}
.layout-action-buttons i {
  color: #3a0ca1 !important;
}
.active-editor {
  border: 2px solid black !important;
  box-shadow: 0 0 4px black !important;
  transform: scale(1.001) !important;
}
